import React from 'react';
import MainTitle from './MainTitle';

const MainSection = () => (
  <section className="main">
    <div className="container">
      <a name="#Main"></a>
      <MainTitle />
    </div>
  </section>
);

export default MainSection;
