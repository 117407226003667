import React from 'react';
import LentaItem from './LentaItem';

const LentaSection = () => (
  <section className="lenta">
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem className="ll_i">About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem className="l_i">About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
    <LentaItem>About us &gt;&gt;</LentaItem>
  </section>
);

export default LentaSection;
